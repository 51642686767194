/*! modernizr 3.11.3 (Custom Build) | MIT *
 * https://modernizr.com/download/?-cssanimations-csstransforms3d-flexbox-mediaqueries-rgba-smil-setclasses !*/
!function (e, n, t, r) {
  function o(e, n) {
    return typeof e === n
  }

  function s(e, n) {
    return !!~("" + e).indexOf(n)
  }

  function i() {
    return "function" != typeof t.createElement ? t.createElement(arguments[0]) : b ? t.createElementNS.call(t, "http://www.w3.org/2000/svg", arguments[0]) : t.createElement.apply(t, arguments)
  }

  function a() {
    var e = t.body;
    return e || (e = i(b ? "svg" : "body"), e.fake = !0), e
  }

  function l(e, n, r, o) {
    var s, l, u, f, c = "modernizr", d = i("div"), p = a();
    if (parseInt(r, 10)) for (; r--;) u = i("div"), u.id = o ? o[r] : c + (r + 1), d.appendChild(u);
    return s = i("style"), s.type = "text/css", s.id = "s" + c, (p.fake ? p : d).appendChild(s), p.appendChild(d), s.styleSheet ? s.styleSheet.cssText = e : s.appendChild(t.createTextNode(e)), d.id = c, p.fake && (p.style.background = "", p.style.overflow = "hidden", f = w.style.overflow, w.style.overflow = "hidden", w.appendChild(p)), l = n(d, e), p.fake ? (p.parentNode.removeChild(p), w.style.overflow = f, w.offsetHeight) : d.parentNode.removeChild(d), !!l
  }

  function u(e) {
    return e.replace(/([A-Z])/g, function (e, n) {
      return "-" + n.toLowerCase()
    }).replace(/^ms-/, "-ms-")
  }

  function f(e, t, r) {
    var o;
    if ("getComputedStyle" in n) {
      o = getComputedStyle.call(n, e, t);
      var s = n.console;
      if (null !== o) r && (o = o.getPropertyValue(r)); else if (s) {
        var i = s.error ? "error" : "log";
        s[i].call(s, "getComputedStyle returning null, its possible modernizr test results are inaccurate")
      }
    } else o = !t && e.currentStyle && e.currentStyle[r];
    return o
  }

  function c(e, t) {
    var o = e.length;
    if ("CSS" in n && "supports" in n.CSS) {
      for (; o--;) if (n.CSS.supports(u(e[o]), t)) return !0;
      return !1
    }
    if ("CSSSupportsRule" in n) {
      for (var s = []; o--;) s.push("(" + u(e[o]) + ":" + t + ")");
      return s = s.join(" or "), l("@supports (" + s + ") { #modernizr { position: absolute; } }", function (e) {
        return "absolute" === f(e, null, "position")
      })
    }
    return r
  }

  function d(e) {
    return e.replace(/([a-z])-([a-z])/g, function (e, n, t) {
      return n + t.toUpperCase()
    }).replace(/^-/, "")
  }

  function p(e, n, t, a) {
    function l() {
      f && (delete E.style, delete E.modElem)
    }

    if (a = !o(a, "undefined") && a, !o(t, "undefined")) {
      var u = c(e, t);
      if (!o(u, "undefined")) return u
    }
    for (var f, p, m, v, g, h = ["modernizr", "tspan", "samp"]; !E.style && h.length;) f = !0, E.modElem = i(h.shift()), E.style = E.modElem.style;
    for (m = e.length, p = 0; p < m; p++) if (v = e[p], g = E.style[v], s(v, "-") && (v = d(v)), E.style[v] !== r) {
      if (a || o(t, "undefined")) return l(), "pfx" !== n || v;
      try {
        E.style[v] = t
      } catch (e) {
      }
      if (E.style[v] !== g) return l(), "pfx" !== n || v
    }
    return l(), !1
  }

  function m(e, n) {
    return function () {
      return e.apply(n, arguments)
    }
  }

  function v(e, n, t) {
    var r;
    for (var s in e) if (e[s] in n) return !1 === t ? e[s] : (r = n[e[s]], o(r, "function") ? m(r, t || n) : r);
    return !1
  }

  function g(e, n, t, r, s) {
    var i = e.charAt(0).toUpperCase() + e.slice(1), a = (e + " " + _.join(i + " ") + i).split(" ");
    return o(n, "string") || o(n, "undefined") ? p(a, n, r, s) : (a = (e + " " + N.join(i + " ") + i).split(" "), v(a, n, t))
  }

  function h(e, n, t) {
    return g(e, r, r, n, t)
  }

  var y = [], S = {
    _version: "3.11.3",
    _config: { classPrefix: "", enableClasses: !0, enableJSClass: !0, usePrefixes: !0 },
    _q: [],
    on: function (e, n) {
      var t = this;
      setTimeout(function () {
        n(t[e])
      }, 0)
    },
    addTest: function (e, n, t) {
      y.push({ name: e, fn: n, options: t })
    },
    addAsyncTest: function (e) {
      y.push({ name: null, fn: e })
    }
  }, Modernizr = function () {
  };
  Modernizr.prototype = S, Modernizr = new Modernizr;
  var C = [], w = t.documentElement, b = "svg" === w.nodeName.toLowerCase(), x = "Moz O ms Webkit",
    _ = S._config.usePrefixes ? x.split(" ") : [];
  S._cssomPrefixes = _;
  var T = { elem: i("modernizr") };
  Modernizr._q.push(function () {
    delete T.elem
  });
  var E = { style: T.elem.style };
  Modernizr._q.unshift(function () {
    delete E.style
  });
  var N = S._config.usePrefixes ? x.toLowerCase().split(" ") : [];
  S._domPrefixes = N, S.testAllProps = g, S.testAllProps = h, Modernizr.addTest("cssanimations", h("animationName", "a", !0)), Modernizr.addTest("flexbox", h("flexBasis", "1px", !0));
  var z = function () {
    var e = n.matchMedia || n.msMatchMedia;
    return e ? function (n) {
      var t = e(n);
      return t && t.matches || !1
    } : function (e) {
      var n = !1;
      return l("@media " + e + " { #modernizr { position: absolute; } }", function (e) {
        n = "absolute" === f(e, null, "position")
      }), n
    }
  }();
  S.mq = z, Modernizr.addTest("mediaqueries", z("only all")), Modernizr.addTest("rgba", function () {
    var e = i("a").style;
    return e.cssText = "background-color:rgba(150,255,150,.5)", ("" + e.backgroundColor).indexOf("rgba") > -1
  });
  var P = "CSS" in n && "supports" in n.CSS, k = "supportsCSS" in n;
  Modernizr.addTest("supports", P || k), Modernizr.addTest("csstransforms3d", function () {
    return !!h("perspective", "1px", !0)
  });
  var j = {}.toString;
  Modernizr.addTest("smil", function () {
    return !!t.createElementNS && /SVGAnimate/.test(j.call(t.createElementNS("http://www.w3.org/2000/svg", "animate")))
  }), function () {
    var e, n, t, r, s, i, a;
    for (var l in y) if (y.hasOwnProperty(l)) {
      if (e = [], n = y[l], n.name && (e.push(n.name.toLowerCase()), n.options && n.options.aliases && n.options.aliases.length)) for (t = 0; t < n.options.aliases.length; t++) e.push(n.options.aliases[t].toLowerCase());
      for (r = o(n.fn, "function") ? n.fn() : n.fn, s = 0; s < e.length; s++) i = e[s], a = i.split("."), 1 === a.length ? Modernizr[a[0]] = r : (Modernizr[a[0]] && (!Modernizr[a[0]] || Modernizr[a[0]] instanceof Boolean) || (Modernizr[a[0]] = new Boolean(Modernizr[a[0]])), Modernizr[a[0]][a[1]] = r), C.push((r ? "" : "no-") + a.join("-"))
    }
  }(), function (e) {
    var n = w.className, t = Modernizr._config.classPrefix || "";
    if (b && (n = n.baseVal), Modernizr._config.enableJSClass) {
      var r = new RegExp("(^|\\s)" + t + "no-js(\\s|$)");
      n = n.replace(r, "$1" + t + "js$2")
    }
    Modernizr._config.enableClasses && (e.length > 0 && (n += " " + t + e.join(" " + t)), b ? w.className.baseVal = n : w.className = n)
  }(C), delete S.addTest, delete S.addAsyncTest;
  for (var q = 0; q < Modernizr._q.length; q++) Modernizr._q[q]();
  e.Modernizr = Modernizr
}(window, window, document);
